import { Box, Card, Grid, IconButton, Switch } from "@mui/material";
import { UserModel } from "api/services/user/models";
import { SuiAvatarTs } from "components/SuiAvatar";
import { SuiBadgeTs } from "components/SuiBadge";
import { SuiTypographyTs } from "components/SuiTypography";
import CircleContainer from "components/ui/CircleContainer";
import { groupColorMap } from "components/ui/contants";
import LabelValuePair from "components/ui/LabelValuePair";
import React, { useRef } from "react";
import { format } from "rut.js";
import { Delete, Person, Settings } from "@mui/icons-material";
import eventEmitter from "lib/events/EventEmitter";
import DropdownMenu, { DropdownMenuItem } from "components/DropdownMenu";
import { MdLockPerson } from "react-icons/md";
import colors from "assets/theme/base/colors";
import Swal from "sweetalert2";
import useDeactivateUser from "api/services/user/mutations/deactivateUser";
import GroupsIcon from "@mui/icons-material/Groups";
import SuiButton, { SuiButtonTs } from "components/SuiButton";
import UpdateUserModal from "../users/management/UpdateUserModal";
import { ModalRefHandle } from "components/ui/BaseModal";
import ManageUserPermissionsModal from "../users/management/ManageUserPermissionsModal";
import ManageUserCommunitiesModal from "../users/management/ManageUserCommunitiesModal";
const pairOptions = {
  labelWidth: 3,
  valueWidth: 9,
};
const UserProfileInfoCard: React.FC<{ user: UserModel }> = ({ user }) => {
  const { mutate: deactivateUser } = useDeactivateUser(user?.uuid);
  const updateUserModalRef = useRef<ModalRefHandle>(null);
  const manageCommunitiesModalRef = useRef<ModalRefHandle>(null);
  const managePermissionsModalRef = useRef<ModalRefHandle>(null);

  const menuItems1: DropdownMenuItem[] = [
    {
      label: "Editar usuario",
      icon: <Person />,
      onClick: () => updateUserModalRef.current.open(),
    },
    !["conserje", "mayordomo"].includes(user.groups[0].name) && {
      label: "Asignar comunidades",
      icon: <GroupsIcon />,
      onClick: () => manageCommunitiesModalRef.current.open(),
    },
    {
      label: "Asignar permisos",
      icon: <MdLockPerson />,
      onClick: () => managePermissionsModalRef.current.open(),
    },
  ];
  const menuItems2: DropdownMenuItem[] = [
    {
      label: "Eliminar usuario",
      icon: <Delete color="inherit" />,
      color: colors.error.main,
      onClick: () =>
        Swal.fire({
          title: "¿Quieres eliminar este usuario?",
          showDenyButton: true,
          confirmButtonText: "Si",
          denyButtonText: `No`,
        }).then((result) => {
          if (result.isConfirmed) {
            deactivateUser(null, {
              onSuccess: () => {
                eventEmitter.publish("userDeactivated", user.uuid);
              },
            });
          }
        }),
    },
  ];
  if (!user) return <Card>No hay información</Card>;
  return (
    <>
      <UpdateUserModal ref={updateUserModalRef} user={user} />
      <ManageUserPermissionsModal ref={managePermissionsModalRef} user={user} />
      <ManageUserCommunitiesModal ref={manageCommunitiesModalRef} user={user} />

      <Card sx={{ p: 3 }}>
        <Box display={"flex"} gap={4}>
          <SuiTypographyTs variant="h5" fontWeight="medium">
            Información del perfil
          </SuiTypographyTs>
        </Box>
        <Box mt={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={1.2}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Box mb={2}>
                  <CircleContainer size={90}>
                    <SuiAvatarTs
                      size="xl"
                      uuid={user.uuid}
                      alt={user.first_name}
                    />
                  </CircleContainer>
                </Box>
                <SuiBadgeTs
                  container
                  size="xxl"
                  variant="gradient"
                  color={groupColorMap[user.groups[0].name]}
                  badgeContent={user.groups[0].name}
                />

                  <DropdownMenu
                    items={[menuItems1, menuItems2]}
                    renderButton={({ onClick }) => (
                      <SuiButtonTs
                        sx={{ mt: 2 }}
                        variant="gradient"
                        color="secondary"
                        size="small"
                        onClick={onClick}
                        fullWidth
                      >
                        Configuración{"  "}
                      </SuiButtonTs>
                    )}
                  />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <LabelValuePair
                label="Nombre"
                value={user.first_name}
                {...pairOptions}
              />
              <LabelValuePair
                label="Teléfono"
                value={user.phone}
                {...pairOptions}
              />
              <LabelValuePair
                label="Email"
                value={user.email}
                {...pairOptions}
              />
              <LabelValuePair
                label="Rut"
                value={format(user.rut, { dots: true })}
                {...pairOptions}
              />
              <LabelValuePair
                label="Reporte semanal"
                value={user.weekly_report_notification ? "Si" : "No"}
                {...pairOptions}
              />
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
};

export default UserProfileInfoCard;
