/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";

// Soft UI Dashboard PRO React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavList from "examples/Sidenav/SidenavList";
import SidenavItem from "examples/Sidenav/SidenavItem";
import SidenavCard from "examples/Sidenav/SidenavCard";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Soft UI Dashboard PRO React context
import { useSoftUIController, setMiniSidenav } from "context";
import { getPermission } from "utils";
import useMediaScreen from "utils/Hooks/useMedia";
import useGetAuthUserProfile from "api/services/user/queries/getUserSessionProfile";

function Sidenav({ color, brand, brandIso, brandName, routes, ...rest }) {
  const location = useLocation();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const profile = useGetAuthUserProfile();

  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const { pathname } = location;
  const [collapseName, itemName] = pathname.split("/").slice(1);
  const activeRoute = "/" + pathname.substring(pathname.indexOf("/") + 1);

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      // setMiniSidenav(dispatch, window.innerWidth < 1200);
    }

    /**
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };

  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses.map(({ name, collapse, route, href, key }) => {
      let returnValue;
      if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            name={name}
            active={activeRoute === itemName}
            open={openNestedCollapse === name}
            onClick={() =>
              openNestedCollapse === name
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(name)
            }
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem name={name} active={route === activeRoute} />
          </Link>
        ) : (
          <NavLink to={route} key={key} sx={{ textDecoration: "none" }}>
            <SidenavItem name={name} active={key === itemName} />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map((item) => {
    const { type, name, icon, title, collapse, key, href, path, permission } =
      item;
    if (permission?.length > 0 && !getPermission(permission)) {
      return null;
    }

    if (type === "collapse") {
      if (href) {
        return (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={name}
              icon={icon}
              active={path === activeRoute}
              noCollapse={isSidebarItem}
            />
          </Link>
        );
      }
      if (path) {
        let newPath = path;
        if (
          path.includes(":slug") &&
          getPermission(["comite", "conserje", "mayordomo"])
        ) {
          
          let communitySlug = profile.data?.communities[0].slug;
          newPath = path.replace(":slug", communitySlug);
        }
        return (
          <NavLink to={newPath} key={key}>
            <SidenavCollapse
              name={name}
              icon={icon}
              active={location.pathname.startsWith("/" + path.split("/")[1])}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          </NavLink>
        );
      }
      return (
        <SidenavCollapse
          key={key}
          name={name}
          icon={icon}
          active={path === activeRoute}
          open={openCollapse === key}
          onClick={() =>
            openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key)
          }
        >
          {collapse ? renderCollapse(collapse) : null}
        </SidenavCollapse>
      );
    }
    if (type === "title") {
      return (
        <SuiTypography
          key={key}
          display="block"
          variant="caption"
          fontWeight="bold"
          textTransform="uppercase"
          opacity={0.6}
          pl={3}
          mt={2}
          mb={1}
          ml={1}
        >
          {title}
        </SuiTypography>
      );
    }
    if (type === "divider") {
      return <Divider key={key} />;
    }

    return null;
  });

  return (
    <SidenavRoot
      keepMounted={true}
      {...rest}
      ownerState={{ transparentSidenav, miniSidenav }}
    >
      <SuiBox pt={3} pb={1} px={4} textAlign="center">
        <SuiBox component={NavLink} to={"/"} display="flex" alignItems="center">
          {brand && (
            <SuiBox
              component="img"
              src={miniSidenav ? brandIso : brand}
              alt="Swappi Logo"
              width={miniSidenav ? 40 : 200}
              maxHeight={miniSidenav ? 40 : 50}
            />
          )}
          {/* <SuiBox
            width={!brandName && '100%'}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <SuiTypography component="h6" variant="button" fontWeight="medium">
              {brandName}
            </SuiTypography>
          </SuiBox> */}
        </SuiBox>
      </SuiBox>
      <Divider />
      <List>{renderRoutes}</List>

      <SuiBox pt={2} my={2} mx={2}>
        <SidenavCard />
      </SuiBox>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
