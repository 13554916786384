/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import SuiAvatar from "components/SuiAvatar";
import { getPermission } from "utils";
// Soft UI Dashboard PRO React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Soft UI Dashboard PRO React data variables
import { capitalize } from "utils";
import SuiBadge from "components/SuiBadge";

import Create from "layouts/pages/admin-team/components/Post/Members/Create";
import { getComiteByCommunitySlug } from "redux/actions/users";
import DataTable from "examples/Tables/DataTable";
import LoadingView from "components/Loading";
import Swal from "sweetalert2";
import { deactiveUser } from "redux/actions/users";
import { isPermissionEnabled } from "utils";
import { Tooltip } from "@mui/material";
import EditUser from "layouts/pages/admin-team/EditUser";
import Permissions from "layouts/pages/admin-team/permissions/View";
import { useAuthenticationStore } from "stores/authentication";
import ModalRefHandle from "components/ui/BaseModal";
import ManageUserPermissionsModal from "components/modules/users/management/ManageUserPermissionsModal";

function Comite() {
  const { borderWidth } = borders;
  const { slug } = useParams();
  const [team, setteam] = useState({ columns: [], rows: [] });
  const [selected, setselected] = useState(0);
  const dispatch = useDispatch();
  const comiteResponse = useSelector(
    (state) => state.users.getComiteByCommunitySlug
  );
  const createUserResponse = useSelector((state) => state.users.createUser);
  const deleteUserReponse = useSelector((state) => state.users.deactiveUser);
  const updateUserReponse = useSelector(
    (state) => state.users.updateUserWithAdmin
  );
  const managePermissionsModalRef = useRef(ModalRefHandle);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    dispatch(getComiteByCommunitySlug(slug));
  }, []);

  useEffect(() => {
    if (comiteResponse) {
      if (comiteResponse.data !== undefined) {
        setteam(unitsTableData(comiteResponse));
        setLoading(false);
      }
    }
  }, [comiteResponse]);

  useEffect(() => {
    if (updateUserReponse)
      if (new Date() - updateUserReponse.time < 2000)
        dispatch(getComiteByCommunitySlug(slug));
  }, [updateUserReponse]);

  useEffect(() => {
    if (deleteUserReponse)
      if (new Date() - deleteUserReponse.time < 2000)
        dispatch(getComiteByCommunitySlug(slug));
  }, [deleteUserReponse]);

  useEffect(() => {
    if (createUserResponse.data !== undefined) {
      dispatch(getComiteByCommunitySlug(slug));
    }
  }, [createUserResponse]);

  const unitsTableData = (users) => {
    let columns = [
      { Header: "Nombre", accessor: "name", width: "20%" },
      { Header: "Email", accessor: "email", width: "30%" },
      { Header: "Rut", accessor: "rut", width: "30%" },
      { Header: "Teléfono", accessor: "phone", width: "15%" },
      { Header: "Acciones", accessor: "actions" },
    ];
    if (users.data === undefined) {
      return { columns, rows: [] };
    }

    let rows = users.data.map((users, i) => {
      return {
        name: users.first_name,
        email: users.email,
        rut: users.rut,
        phone: users.phone,
        actions: (
          <SuiBox display="flex" justiftContent="center">
             
            {isPermissionEnabled("committee-editCommittee") && (
              <Tooltip title="Editar" placement="top">
                <EditUser user={users} />
              </Tooltip>
            )}
            {isPermissionEnabled("committee-editCommittee") && (
              <Tooltip title="Editar permisos" placement="top">
                <SuiBadge
                  style={{ cursor: "pointer" }}
                  variant="gradient"
                  color="info"
                  badgeContent={<Icon size="12px">edit</Icon>}
                  sx={{ marginRight: 1 }}
                  onClick={() => {
                    setUser(users);
                    managePermissionsModalRef.current.open();
                  }}
                />
              </Tooltip>
            )}
            {isPermissionEnabled("committee-deleteCommittee") && (
              <Tooltip title="Eliminar" placement="top">
                <SuiBadge
                  style={{ cursor: "pointer" }}
                  variant="gradient"
                  color="error"
                  badgeContent={<Icon size="12px">delete</Icon>}
                  sx={{ marginRight: 1 }}
                  onClick={() =>
                    Swal.fire({
                      title: "¿Quieres eliminar este usuario?",
                      showDenyButton: true,
                      confirmButtonText: "Si",
                      denyButtonText: `No`,
                    }).then((result) => {
                      /* Read more about isConfirmed, isDenied below */
                      if (result.isConfirmed) {
                        let profile =
                          useAuthenticationStore.getState().userProfile;
                        dispatch(deactiveUser({ email: users.email }));
                        Swal.fire("Eliminado", "", "success");
                      } else if (result.isDenied) {
                      }
                    })
                  }
                />
              </Tooltip>
            )}
          </SuiBox>
        ),
      };
    });
    return { columns, rows };
  };

  return (
    <Card sx={{ overflow: "scroll" }}>
      <SuiBox width="100%" pt={3} pb={2.5} px={3}>
        {isPermissionEnabled("committee-createCommittee") && (
          <Create comite={true} />
        )}
        <ManageUserPermissionsModal
          ref={managePermissionsModalRef}
          user={user}
        />
        <Grid container wrap="nowrap">
          {loading && <LoadingView />}
          {!loading && <DataTable table={team} />}
        </Grid>
      </SuiBox>
    </Card>
  );
}

export default Comite;
