/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useMemo, useState, useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
// @mui material components
import Grid from "@mui/material/Grid";
import { mobileMaxWidth, parseDate } from "utils";
// Soft UI Dashboard PRO React components
import SuiBox from "components/SuiBox";
import "assets/css/recordButton.css";
// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import SuiTypography from "components/SuiTypography";
// Calendar application components
import Header from "layouts/pages/profile/components/Header";
import { useParams } from "react-router-dom";
import { getVisitReport } from "redux/actions/reports";
import LoadingView from "components/Loading";
import { Box, Card, Divider, Icon, Typography } from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import SuiButton from "components/SuiButton";
import SuiEditor from "components/SuiEditor";
import UploadFileDrawer from "components/UploadFileDrawer";
import { getPermission } from "utils";
import AddTicketModal from "components/Tickets/AddTicketModal";
import { editVisitReport } from "redux/actions/reports";
import { Tooltip } from "@mui/material";
import SuiBadge from "components/SuiBadge";
import { ticketStatusColor, ticketPriorityColor } from "utils";
import SuiProgress from "components/SuiProgress";
import TicketModal from "components/Tickets/TicketDetailModal";

import { BiArchiveIn, BiMicrophone } from "react-icons/bi";
import DataTable from "examples/Tables/DataTable";
import SuiAvatar from "components/SuiAvatar";
import { getVisitReportPDF } from "redux/actions/reports";
import { GrDocumentPdf } from "react-icons/gr";
import { CircularProgress } from "@mui/material";
import SuiInput from "components/SuiInput";
import { archiveTicket } from "redux/actions/tickets";
import Swal from "sweetalert2";
import { getPublicVisitReport } from "redux/actions/communities";
import { BsPaperclip } from "react-icons/bs";
import PageLayout from "examples/LayoutContainers/PageLayout";
import logo from "assets/images/logo-blue.png";
import { isPermissionEnabled } from "utils";
import Checklist from "components/ChecklistsComponents/Checklist";
import AddChecklistButton from "components/pages/VisitsReports/Actions/AddChecklistButton";
import useEventListener from "lib/events/useEventListener";
import { EVENTS } from "lib/events/events";
import DocumentsImagesCard from "components/VisitsReports/DocumentsImagesCard";
import ShowMoreLess from "components/ShowMore";
import { FaEye } from "react-icons/fa";

const imageDeleteButtonStyle = {
  fontSize: 17,
  color: "white",
  backgroundColor: "red",
  paddingLeft: 11,
  paddingRight: 11,
  paddingTop: 5,
  paddingBottom: 5,
  opacity: 0.9,
  borderRadius: "50%",
  cursor: "pointer",
};

const imageEditButtonStyle = {
  marginTop: "2px",
  marginRight: 5,
  fontSize: 17,
  color: "white",
  backgroundColor: "#fbcf33",
  padding: 10,
  borderRadius: "50%",
  cursor: "pointer",
};

const renderMembers = (members) => (
  <SuiBox display="flex">
    {members.map((member, i) => (
      <Tooltip key={i} title={member.first_name} placement="bottom">
        <SuiAvatar
          src={member.profile_picture}
          alt={member.first_name}
          size="xs"
          sx={({ borders: { borderWidth }, palette: { white } }) => ({
            ml: -1.25,
            border: `${borderWidth[2]} solid ${white.main}`,
            cursor: "pointer",
            position: "relative",
            "&:hover, &:focus": {
              zIndex: "10",
            },
          })}
          style={{ backgroundColor: "black" }}
        />
      </Tooltip>
    ))}
  </SuiBox>
);
// Data
function VisitReport(props) {
  const dispatch = useDispatch();
  const { slug, uuid: paramUuid } = useParams();
  const uuid = paramUuid || props.uuid;
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState({});
  const [editDescription, seteditDescription] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [editedDescription, seteditedDescription] = useState("");
  const [editedTitle, seteditedTitle] = useState("");
  const getReportResponse = useSelector(
    (state) => state.communities.getVisitReport
  );
  const deleteDocumentResponse = useSelector(
    (state) => state.documents.deleteDocument
  );
  const ticketResponse = useSelector((state) => state.tickets.createTicket);
  const addDocument = useSelector((state) => state.documents.addDocument);
  const editFileResponse = useSelector(
    (state) => state.documents.updateDocument
  );
  const editReportResponse = useSelector(
    (state) => state.reports.editVisitReport
  );
  const getReportPdfResponse = useSelector(
    (state) => state.reports.getVisitReportPDF
  );
  const deleteTicketResponse = useSelector(
    (state) => state.tickets.archiveTicket
  );
  const checklistResponse = useSelector(
    (state) => state.tickets.addChecklistToTicket
  );
  const itemChecklistResponse = useSelector(
    (state) => state.tickets.addItemToChecklist
  );
  const deleteChecklistResponse = useSelector(
    (state) => state.tickets.deleteChecklist
  );
  const deleteChecklistItemResponse = useSelector(
    (state) => state.tickets.deleteChecklistItem
  );
  const markChecklistItemResponse = useSelector(
    (state) => state.tickets.markChecklistItem
  );
  const addChecklistToTicketFromTemplateResponse = useSelector(
    (state) => state.tickets.addChecklistToTicketFromTemplate
  );

  const [checklists, setChecklists] = useState([]);
  const [exportingTicket, setexportingTicket] = useState(false);
  useEffect(() => {
    if (props.public) {
      dispatch(getPublicVisitReport(uuid));
    } else dispatch(getVisitReport(uuid));
  }, []);

  const handleArchiveTickets = (uuid) => {
    dispatch(archiveTicket(uuid));
  };

  const doRequest = () => {
    dispatch(getVisitReport(uuid));
  };

  useEventListener(EVENTS.NEW_TICKET, () => {
    doRequest();
  });

  useEffect(() => {
    if (getReportPdfResponse && getReportPdfResponse.data) {
      setexportingTicket(false);
    }
  }, [getReportPdfResponse]);
  useEffect(() => {
    if (ticketResponse && ticketResponse.data) {
      dispatch(getVisitReport(uuid));
    } else if (addDocument && addDocument.data) {
      dispatch(getVisitReport(uuid));
    } else if (editReportResponse && editReportResponse.data) {
      dispatch(getVisitReport(uuid));
    }
  }, [ticketResponse, addDocument, editReportResponse]);

  useEffect(() => {
    if (checklistResponse.data) dispatch(getVisitReport(uuid));
  }, [checklistResponse]);
  useEffect(() => {
    if (addChecklistToTicketFromTemplateResponse.data)
      dispatch(getVisitReport(uuid));
  }, [addChecklistToTicketFromTemplateResponse]);

  useEffect(() => {
    if (markChecklistItemResponse.data) dispatch(getVisitReport(uuid));
  }, [markChecklistItemResponse]);

  useEffect(() => {
    if (itemChecklistResponse) {
      if (itemChecklistResponse.data) dispatch(getVisitReport(uuid));
    }
  }, [itemChecklistResponse]);
  useEffect(() => {
    if (deleteChecklistResponse.data) dispatch(getVisitReport(uuid));
  }, [deleteChecklistResponse]);

  useEffect(() => {
    if (deleteChecklistItemResponse.data) dispatch(getVisitReport(uuid));
  }, [deleteChecklistItemResponse]);

  useEffect(() => {
    if (getReportResponse?.data) {
      setReport(getReportResponse.data.report);
      setChecklists(getReportResponse.data.checklists);
      seteditedDescription(getReportResponse.data.report.body);
      seteditedTitle(getReportResponse.data.report.title);
      setTickets(
        unitsTableData(getReportResponse.data.tickets, handleArchiveTickets)
      );
      setDocuments(getReportResponse.data.documents);
      setLoading(false);
    }
  }, [getReportResponse]);

  useEffect(() => {
    if (deleteTicketResponse && deleteTicketResponse.data) {
      dispatch(getVisitReport(uuid));
    }
  }, [deleteTicketResponse]);

  useEffect(() => {
    if (deleteDocumentResponse && deleteDocumentResponse.data) {
      dispatch(getVisitReport(uuid));
    }
  }, [deleteDocumentResponse]);

  useEffect(() => {
    if (editFileResponse && editFileResponse.data) {
      dispatch(getVisitReport(uuid));
    }
  }, [editFileResponse]);

  const unitsTableData = (tickets, archive) => {
    let columns = [
      { Header: "Título", accessor: "title" },
      { Header: "Comunidad", accessor: "community" },
    ];

    if (window.innerWidth >= mobileMaxWidth) {
      let addColumns = [
        { Header: "Responsables", accessor: "assignees" },
        { Header: "Prioridad", accessor: "priority" },
        { Header: "Estado", accessor: "status" },
        { Header: "Progreso", accessor: "progress" },
        { Header: "Checklist", accessor: "checklists_progress" },
        { Header: "Fecha inicio", accessor: "created" },
        { Header: "Fecha término", accessor: "end_date" },
      ];
      columns = columns.concat(addColumns);
    }
    if (slug && window.innerWidth >= mobileMaxWidth) {
      columns = [
        { Header: "Título", accessor: "title" },
        { Header: "Responsables", accessor: "assignees" },
        { Header: "Prioridad", accessor: "priority" },
        { Header: "Estado", accessor: "status" },
        { Header: "Progreso", accessor: "progress" },
        { Header: "Checklist", accessor: "checklists_progress" },
        { Header: "Fecha inicio", accessor: "created" },
        { Header: "Fecha término", accessor: "end_date" },
        { Header: "Acciones", accessor: "actions" },
      ];
    }
    columns.push({ Header: "Acciones", accessor: "actions" });
    if (tickets === undefined) {
      console.log(tickets);
      return { columns, rows: [] };
    }

    let rows = tickets.map((ticket, i) => {
      let date = Date.parse(ticket.due_date);
      return {
        title: ticket.title,
        code: ticket.code,
        community: ticket.community.name,
        title: (
          <TicketModal
            uuid={ticket.uuid}
            title={ticket.title}
            community={ticket.community}
            update={doRequest}
          />
        ),
        assignees: renderMembers(ticket.assignees),
        status: (
          <SuiBadge
            style={{ float: "left" }}
            badgeContent={ticket.status}
            color={ticketStatusColor[ticket.status]}
            size="xs"
            container
          />
        ),
        progress: (
          <SuiProgress
            style={{ width: "100%" }}
            value={(parseFloat(ticket.progress) * 100).toFixed()}
            variant="gradient"
            label
          />
        ),
        checklists_progress: (
          <SuiProgress
            style={{ width: "100%" }}
            value={(parseFloat(ticket.checklists_progress) * 100).toFixed()}
            variant="gradient"
            label
          />
        ),
        documents:
          ticket.document_number && ticket.document_number > 0 ? "Si" : "No",
        priority: (
          <SuiBadge
            style={{ float: "left" }}
            badgeContent={ticket.priority}
            color={ticketPriorityColor[ticket.priority]}
            size="xs"
            container
          />
        ),
        created: ticket.created && parseDate(ticket.created),
        end_date: ticket.due_date && parseDate(date),
        area: ticket.area ? ticket.area.name : "",
        actions: (
          <div>
            {props.public ? (
              <a href={`https://app.swappi.cl/ticket/public/${ticket.uuid}`}
                target="_blank"
              >
                <SuiBadge
                badgeContent={<FaEye size="16px" />}
                color="info"
                size="xs"
                container
              />
              </a>
            ) : (
              <TicketModal
                uuid={ticket.uuid}
                community={ticket.community}
                update={doRequest}
              />
            )}
            {isPermissionEnabled("tasks-deleteTask") && (
              <SuiBadge
                container
                badgeContent={<BiArchiveIn size="16px" />}
                size="xs"
                style={{ cursor: "pointer", marginTop: 2 }}
                onClick={() => {
                  Swal.fire({
                    title: "Quieres eliminar esta tarea?",
                    showDenyButton: true,
                    confirmButtonText: "Si",
                    denyButtonText: `No`,
                  }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                      archive(ticket.uuid);
                      Swal.fire("Archivado!", "", "Success");
                    } else if (result.isDenied) {
                      Swal.fire("Tarea no archivada", "", "error");
                    }
                  });
                }}
                color={"error"}
              ></SuiBadge>
            )}
          </div>
        ),
      };
    });
    return { columns, rows };
  };
  const renderContent = () => {
    return (
      <SuiBox pt={3}>
        <TicketModal create={true} update={doRequest} />
        <SuiBox style={{ display: "flex", justifyContent: "center" }}>
          {props.public && (
            <a
              style={{ margin: "0 auto" }}
              target="_blank"
              href="https://app.swappi.cl"
            >
              <img src={logo} style={{ width: "10rem", margin: "0 auto" }} />
            </a>
          )}
        </SuiBox>

        <SuiTypography
          variant="h4"
          fontWeight="medium"
          textAlign="center"
          mt={1}
          mb={1}
        >
          Reporte Visita
        </SuiTypography>
        <SuiBox display="flex" justifyContent="flex-end">
          {exportingTicket ? (
            <SuiBox style={{ textAlign: "center" }}>
              <CircularProgress size={20} />
            </SuiBox>
          ) : (
            <SuiBox>
              <SuiButton
                size="small"
                variant="text"
                color={"dark"}
                onClick={() => {
                  navigator.clipboard.writeText(
                    "https://app.swappi.cl/public/visits/report/" + report.uuid
                  );
                }}
              >
                Copiar Enlace
                <BsPaperclip />
              </SuiButton>
              {!props.public && (
                <SuiButton
                  color="dark"
                  variant={"text"}
                  size="small"
                  onClick={() => {
                    if (exportingTicket) return;
                    setexportingTicket(true);
                    dispatch(getVisitReportPDF(report.uuid));
                  }}
                >
                  Exportar Pdf
                  <GrDocumentPdf
                    style={{ marginLeft: 10, cursor: "pointer" }}
                  />
                </SuiButton>
              )}
            </SuiBox>
          )}
          {getPermission(["superadmin", "administrador", "supervisor"]) &&
            !props.public &&
            isPermissionEnabled("fieldVisits-editVisit") && (
              <Tooltip
                placement="top"
                title="Editar"
                sx={{ cursor: "pointer" }}
              >
                <SuiButton
                  color="dark"
                  variant="text"
                  onClick={() => {
                    if (!props.public) {
                      seteditDescription(!editDescription);
                      seteditedDescription(report.body);
                    }
                  }}
                >
                  Editar <Icon>edit</Icon>
                </SuiButton>
              </Tooltip>
            )}
        </SuiBox>
        <Card sx={{ p: 2 }}>
          <Box display={"flex"} gap={2}>
            <Box flex={1}>
              {!editDescription ? (
                <Typography
                  variant="h4"
                  fontWeight="medium"
                  onClick={() => {
                    if (!props.public) {
                      seteditDescription(!editDescription);
                    }
                  }}
                >
                  {report.title}
                </Typography>
              ) : (
                <SuiInput
                  size="small"
                  value={editedTitle}
                  onChange={(e) => {
                    seteditedTitle(e.target.value);
                  }}
                />
              )}
            </Box>
            <Box>
              <SuiTypography variant="h6" fontWeight="medium">
                {report.community.name}
              </SuiTypography>
            </Box>
          </Box>
          <Divider />
          <Box mt={2}>
            <Box>
              <SuiTypography
                fontWeight="medium"
                onClick={() => {
                  if (!props.public) {
                    seteditDescription(!editDescription);
                    seteditedDescription(report.body);
                  }
                }}
                component="h5"
              >
                Descripción
              </SuiTypography>
            </Box>
            <Box>
              {!editDescription ? (
                <ShowMoreLess height={210}>
                  <Box>
                    {!editedDescription && (
                      <div
                        onClick={() => {
                          if (!props.public) {
                            seteditDescription(!editDescription);
                          }
                        }}
                        style={{ textAlign: "center", width: "100%" }}
                      >
                        <p>No hay descripción</p>
                      </div>
                    )}
                    <SuiBox p={0}>
                      <SuiTypography
                        variant="body2"
                        style={{ cursor: "pointer" }}
                        component={"span"}
                        onClick={() => {
                          if (!props.public) {
                            seteditDescription(!editDescription);
                          }
                        }}
                        color="text"
                        className="ql-editor"
                      >
                        {ReactHtmlParser(editedDescription)}
                      </SuiTypography>
                    </SuiBox>
                  </Box>
                </ShowMoreLess>
              ) : (
                <SuiBox p={1}>
                  <SuiEditor
                    mb={2}
                    size="small"
                    onChange={(e) => {
                      seteditedDescription(e);
                    }}
                    multiline
                    rows={10}
                    value={editedDescription}
                  />
                  <SuiBox style={{ float: "right", marginTop: 5 }}>
                    <SuiButton
                      size="small"
                      color="success"
                      variant="gradient"
                      onClick={() => {
                        dispatch(
                          editVisitReport({
                            uuid: report.uuid,
                            body: editedDescription,
                            title: editedTitle,
                          })
                        );
                        seteditDescription(!editDescription);
                      }}
                    >
                      Guardar
                    </SuiButton>
                    <SuiButton
                      size="small"
                      color="error"
                      variant="gradient"
                      onClick={() => seteditDescription(!editDescription)}
                    >
                      Cancelar
                    </SuiButton>
                  </SuiBox>
                </SuiBox>
              )}
            </Box>
          </Box>
        </Card>
        <Divider />
        <Card sx={{ padding: 3, marginTop: 2 }}>
          <Box>
            {checklists && (
              <Box>
                <Box display="flex" justifyContent="space-between" mb={2}>
                  <SuiTypography fontWeight="medium" component="h5">
                    Checklists
                  </SuiTypography>
                  {!props.public && (
                    <AddChecklistButton
                      objectUUID={uuid}
                      onAdd={() => doRequest()}
                    />
                  )}
                </Box>
                {checklists.length === 0 && (
                  <div style={{ textAlign: "center", width: "100%" }}>
                    <p>No hay checklists disponibles</p>
                  </div>
                )}

                {checklists.map((checklist, i) => (
                  <Checklist
                    key={i}
                    checklist={checklist}
                    objectUUID={uuid}
                    editEnabled={!props.public}
                    onEdit={() => {
                      doRequest(); // improve when refactor
                    }}
                    onDelete={() => {
                      doRequest(); // improve when refactor
                    }}
                  />
                ))}
              </Box>
            )}
          </Box>
        </Card>
        <Divider />
        <Box mt={2}>
          <DocumentsImagesCard documents={documents} report={report} />
        </Box>
        <Divider />
        <Card sx={{ padding: 3, mt: 1 }}>
          <SuiBox style={{ display: "flex", justifyContent: "space-between" }}>
            <SuiTypography fontWeight="medium" component="h5">
              Tareas
            </SuiTypography>
            {isPermissionEnabled("fieldVisits-editVisit") && (
              <AddTicketModal
                visitReport={report.uuid}
                community={report.community.slug}
              />
            )}
          </SuiBox>
          {tickets.rows.length === 0 && (
            <div style={{ textAlign: "center", width: "100%" }}>
              <p>No hay tareas disponibles</p>
            </div>
          )}
          {tickets.rows.length > 0 && <DataTable table={tickets} />}
        </Card>
      </SuiBox>
    );
  };
  return props.public ? (
    loading ? (
      <LoadingView />
    ) : (
      <PageLayout overflow={"scroll"}>
        <SuiBox p={3}>{renderContent()}</SuiBox>
      </PageLayout>
    )
  ) : loading ? (
    <LoadingView />
  ) : (
    renderContent()
  );
}

export default VisitReport;
