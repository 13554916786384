/**
=========================================================
* Soft UI Dashboard PRO React - v3.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
// Soft UI Dasboard PRO Material components
import { SuiBadgeTs } from "components/SuiBadge";
import { useNavigate, useParams } from "react-router-dom";

import { parseDate } from "utils";
import CreateMaintenanceModal from "../Create";
import { useGetMaintenancesList } from "api/services/maintenances/queries/getMaintenancesList";
import SwappiDataTable from "components/SwappiTable";
import { Box, Stack, Typography } from "@mui/material";
import { AiOutlineEye } from "react-icons/ai";
import { useForm } from "react-hook-form";
import SelectControlled from "components/forms/controlled/SelectControlled";
import { useGetCommunities } from "api/services/communities/queries/GetCommunities";
import { useGetProviders } from "api/services/providers/queries/getProviders";
import { UUID } from "crypto";
import { MaintenanceModel } from "api/services/maintenances/models";

import useDebounce from "utils/Hooks/useDebounce";
import { ColumnDef } from "@tanstack/react-table";
import { useCreateColumns } from "./table.helper";

interface FiltersForm {
  community: string;
  service_provider: UUID;
}

const Maintenances = () => {
  const { dataAsOptions: communityOptions } = useGetCommunities();
  const { dataAsOptions: providersOptions } = useGetProviders();
  const { slug } = useParams();

  const [maintenancesParams, setMaintenancesParams] = useState({
    page: 1,
    page_size: 20,
    community: slug ? slug : "",
    service_provider: "" as UUID,
  });

  const columns = useCreateColumns();

  const { data, isFetching } = useGetMaintenancesList(maintenancesParams);
  const { control, watch } = useForm<FiltersForm>({
    defaultValues: {
      community: slug ? slug : "",
      service_provider: "" as UUID,
    },
  });

  const debounceParams = useDebounce((values: FiltersForm) => {
    setMaintenancesParams((state) => ({ ...state, page: 1, ...values }));
  }, 800);

  useEffect(() => {
    const subscription = watch((value) => {
      debounceParams(value as FiltersForm);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const handleOnPageChange = (page: number) => {
    setMaintenancesParams((state) => ({
      ...state,
      page,
    }));
  };
  const handleOnPageSizeChange = (pageSize: number) => {
    setMaintenancesParams((state) => ({
      ...state,
      page: 1,
      page_size: pageSize,
    }));
  };

  return (
    <Box p={0} pt={4}>
      <Typography variant="h4" fontWeight="medium" textAlign="center">
        Mantenciones
      </Typography>
      {/* <MaintenanceModal modal={true} createdView={true} uuid={createdMaintenance} /> */}

      <Divider />
      <Box>
        <Card sx={{ p: 2 }}>
          <Box>
            <Typography variant="h5" fontWeight="medium">
              Filtros
            </Typography>
            <Box mt={2}>
              <Grid container spacing={3}>
                {!slug && (
                  <Grid item xs={12} md={6} lg={6}>
                    <SelectControlled
                      control={control}
                      name="community"
                      label="Comunidad"
                      placeholder="Comunidad"
                      options={[
                        {
                          value: "",
                          label: "Todas",
                        },
                        ...communityOptions,
                      ]}
                    />{" "}
                  </Grid>
                )}

                <Grid item xs={12} md={6} lg={6}>
                  <SelectControlled
                    control={control}
                    name="service_provider"
                    label="Proveedores"
                    placeholder="Proveedor"
                    options={[
                      {
                        value: "",
                        label: "Todos",
                      },
                      ...providersOptions,
                    ]}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt={2} justifyContent="flex-end" display="flex">
              <Box>
                <SuiBadgeTs
                  color="success"
                  badgeContent={""}
                  variant="gradient"
                />{" "}
                <span style={{ fontSize: 14 }}>Activo </span>
              </Box>
              <Box>
                <SuiBadgeTs
                  color="warning"
                  badgeContent={""}
                  variant="gradient"
                />{" "}
                <span style={{ fontSize: 14 }}>Vencido </span>
              </Box>
              <Box>
                <SuiBadgeTs
                  color="error"
                  badgeContent={""}
                  variant="gradient"
                />{" "}
                <span style={{ fontSize: 14 }}>No existe </span>
              </Box>
            </Box>
            <Divider />
            <CreateMaintenanceModal />
          </Box>
          <SwappiDataTable
            columns={columns}
            data={data?.results}
            isLoading={isFetching}
            defaultPageSize={maintenancesParams.page_size}
            onChangePageSize={handleOnPageSizeChange}
            pagination={{
              count: data?.count,
              previous: data?.previous,
              next: data?.next,
              currentPage: maintenancesParams.page,
              onChangePage: handleOnPageChange,
            }}
          />
        </Card>
      </Box>
    </Box>
  );
};

export default Maintenances;
