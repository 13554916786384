import { Box } from "@mui/material";
import { UserModel } from "api/services/user/models";
import { SuiTypographyTs } from "components/SuiTypography";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "layouts/pages/account/settings/components/TableCell";
import SwitchControlled from "components/forms/controlled/SwitchControlled";
import { SuiButtonTs } from "components/SuiButton";
import useUpdateUserPermissions from "api/services/user/mutations/updateUserPermission";
import eventEmitter from "lib/events/EventEmitter";
const ManageUserPermissionsForm: React.FC<{ user: UserModel }> = ({ user }) => {
  const { mutate: updatePermission, isPending } = useUpdateUserPermissions(
    user.uuid
  );
  const {
    handleSubmit,
    control,
    formState: { isDirty },
  } = useForm({
    defaultValues: useMemo(() => {
      return user.permissions;
    }, [user.permissions]),
  });

  const onSubmit = handleSubmit((data) => {
    updatePermission(data, {
      onSuccess: (data) => {
        eventEmitter.publish("userUpdated", data);
      },
    });
  });

  return (
    <form onSubmit={onSubmit}>
      <Box
        sx={{
          overflowY: "scroll",
          overflowX: "none",
          height: "75vh",
          width: "100%",
        }}
      >
        {Object.keys(user.permissions).map((permission) => {
          return (
            <Box p={2} key={permission}>
              <Table sx={{ minWidth: "36rem", paddingTop: 10 }}>
                <Box component="thead">
                  <TableRow>
                    <TableCell width="100%" padding={[1.5, 3, 1.5, 0.5]}>
                      {user.permissions[permission].label}
                    </TableCell>
                    <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                      Estado
                    </TableCell>
                  </TableRow>
                </Box>
                <TableBody>
                  {Object.keys(user.permissions[permission]).map((item) => {
                    return (
                      user.permissions[permission][item].label && (
                        <TableRow>
                          <TableCell padding={[1, 1, 1, 0.5]}>
                            <SuiTypographyTs
                              display="block"
                              variant="button"
                              fontWeight="regular"
                            >
                              {user.permissions[permission][item].label}
                            </SuiTypographyTs>
                          </TableCell>
                          <TableCell align="center" padding={[1, 1, 1, 0.5]}>
                            <SwitchControlled
                              name={`${permission}.${item}.enabled`}
                              control={control}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          );
        })}
      </Box>
      <Box display={"flex"} justifyContent={"flex-end"} m={2}>
        <SuiButtonTs
          color="success"
          variant="gradient"
          size="small"
          type="submit"
          disabled={!isDirty || isPending}
        >
          Guardar cambios
        </SuiButtonTs>
      </Box>
    </form>
  );
};

export default ManageUserPermissionsForm;
