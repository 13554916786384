import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { toastNotification } from "components/ui/ToastNotification";
import { UUID } from "crypto";
import { API_URL } from "redux/actions/types";
import { UserModel } from "../models";

interface Input {
  first_name: string;
  phone: string;
  rut: string;
  weekly_report_notification: boolean;
}
const useUpdateUser = (userUUID: UUID) => {
  return useMutation({
    mutationFn: async (input: Input) => {
      const url = `${API_URL}/users/${userUUID}/update/`;
      return axios.put<UserModel>(url, input).then((res) => res.data);
    },
    onSuccess: () => {
      toastNotification.success({
        title: "Usuario actualizado",
        message: "Usuario actualizado con exito",
      });
    },
  });
};

export default useUpdateUser;
