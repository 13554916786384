import React, { useMemo } from "react";

import Grid from "@mui/material/Grid";

import { SuiButtonTs } from "components/SuiButton";
import {
  validate as validateRut,
  clean as cleanRut,
  format as formatRut,
} from "rut.js";
import { useForm } from "react-hook-form";
import InputControlled from "components/forms/controlled/InputControlled";
import { Box } from "@mui/material";
import useEventEmitter from "lib/events/useEventEmitter";
import { UserModel } from "api/services/user/models";
import useUpdateUser from "api/services/user/mutations/updateUser";
import SwitchControlled from "components/forms/controlled/SwitchControlled";

const UpdateUserForm: React.FC<{ user: UserModel }> = ({ user }) => {
  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
  } = useForm({
    mode: "onBlur",
    defaultValues: useMemo(() => {
      return {
        first_name: user.first_name,
        phone: user.phone,
        email: user.email,
        rut: formatRut(user.rut),
        weekly_report_notification: user.weekly_report_notification,
      };
    }, [user]),
  });
  const { mutate: updateUser } = useUpdateUser(user.uuid);
  const userUpdated = useEventEmitter("userUpdated");
  const onSubmit = handleSubmit((data) => {
    updateUser(
      {
        first_name: data.first_name,
        phone: data.phone,
        rut: cleanRut(data.rut),
        weekly_report_notification: data.weekly_report_notification,
      },
      {
        onSuccess: (data) => userUpdated.emit(data),
      }
    );
  });

  return (
    <form onSubmit={onSubmit}>
      <Grid container columnSpacing={3}>
        <Grid item xs={12} sm={6}>
          <InputControlled
            label="Nombre"
            name="first_name"
            control={control}
            placeholder="Ingrese su nombre"
            rules={{ required: "*Este campo es requerido" }}
            requiredDot
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <InputControlled
            label="Teléfono"
            name="phone"
            control={control}
            placeholder="+56991652881"
            rules={{ required: "*Este campo es requerido" }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputControlled
            label="Rut"
            placeholder="11111111-1"
            name="rut"
            control={control}
            format={(value) => formatRut(value)}
            rules={{
              required: "*Este campo es requerido",
              validate: (value) =>
                validateRut(value) || "El rut ingresado no es válido",
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SwitchControlled
            label="Reporte semanal"
            name="weekly_report_notification"
            control={control}
          />
        </Grid>
      </Grid>

      <Box display={"flex"} justifyContent={"flex-end"} mt={2}>
        <SuiButtonTs
          color="success"
          variant="gradient"
          size="small"
          type="submit"
          disabled={!isValid || !isDirty}
        >
          Guardar cambios
        </SuiButtonTs>
      </Box>
    </form>
  );
};

export default UpdateUserForm;
